@import "~vars"

.due-date
	+tablet()
		bottom unset
		top -20px

$itemPadding = 20px
$columnSize = 170px
$toggleSize = 14px * 3 // 14px - icon width, 14*2 toggle paddings

.history-list
	margin-top 5px
	background $container
	border-bottom 1px solid $gray-l

	.history-list-container &
		margin-top (- $itemPadding)
		margin-bottom @margin-top
		border-bottom-color transparent
		+tablet()
			margin-top 0
			background-color transparent

.history-list__li
	position relative
	&.isDisabled
		pointer-events none
		.history-list__item-toggle
			visibility hidden

	&:not(:first-child)
		&:before
			content ''
			absolute left top
			right 0
			height 1px
			background $gray-l
			margin 0
			+tablet()
				margin 0 24px
			+phone()
				margin 0 12px
	+tablet()
		&.active
		&.active + li
			&:before
				display none

.history-list__item
	padding 0 24px
	transition border-color $tdur
	+tablet()
		padding 16px 24px
	+phone()
		padding 12px

	&.active
		background #F1F3EF
		box-sizing border-box
		+tablet()
			border none
			.history-list__item-city
			.history-list__item-total-price
			.history-list__item-toggle
				color $primary
		+phone()
			border 1px solid $gray-l

.history-list__item-head
	min-height 72px
	align-items center
	display table
	table-layout fixed
	width 100%
	height @min-height
	cursor pointer
	transition color $tdur
	+tablet()
		position relative
	+hover()
		&:hover
			color $primary

.history-list__item-date
.history-list__item-city,
.history-list__item-check-type
.history-list__item-total-price
.history-list__item-total-points
.history-list__item-toggle
	display table-cell
	vertical-align middle
	padding 24px $itemPadding
	img
		width 25px
	+tablet()
		display block

.history-list__item-check-type
	+desktop()
		display none
	+tablet()
		display block

.history-list__item-date
	font-size 15px
	lh(18)
	color $gray
	padding-left 0
	width 230px
	+desktop()
		width 150px
	+tablet()
		position absolute
		bottom 0
		left 0
		font-size 14px
		padding 0
	+phone()
		font-size 12px

	> span
		+media(900)
			display block
			&:not(:first-child)
				margin-left 0
		+tablet()
			display inline-block

		&:not(:first-child)
			margin-left 15px

.history-list__item-city
	+tablet()
		padding-bottom 25px
		padding-left 0
		padding-top 8px
		font-size 16px
		float left
		width 55%
	+phone()
		font-size 15px

.history-list__item-total-price
.history-list__item-total-points
	text-align right
	font-size 18px
	lh(22)
	width $columnSize
	white-space nowrap
	+tablet()
		margin-left auto
		padding 10px 0 8px 0
		width 35%

.history-list__item-total-price
	+tablet()
		font-size 18px
		font-weight $semibold
	+phone()
		font-size 15px


.history-list__item-total-points,.history-list__item-check-type
	font-weight $semibold
	+tablet()
		color $gray
		font-size 16px
		padding 0
	+phone()
		font-size 14px
.history-list__item-total-points
		+tablet()
			font-size 14px
		+phone()
			font-size 13px

.history-list__item-toggle
	padding 24px 0
	width $toggleSize
	align-self stretch
	cursor pointer
	text-align center
	+tablet()
		margin-right 0
		margin-left auto
		padding 17px 0 0
		text-align right

	.icon-chevron-down
		margin auto

		.history-list__item.active &
			transform rotate(180deg)

// BODY
.history-list__item-body
	padding 0 $itemPadding
	margin 0 (- $itemPadding)
	+tablet()
		padding 0
		margin 0