.proposal-image-item {
  position: relative;
  text-align: center;
  background: #fff;
  -webkit-transition: box-shadow 0.35s;
  -moz-transition: box-shadow 0.35s;
  -o-transition: box-shadow 0.35s;
  -ms-transition: box-shadow 0.35s;
  transition: box-shadow 0.35s;
  height: 368px;
  padding: 15px 20px !important;
  border-radius: 5px;
/*&:not(.show-mark):hover
		.proposal-image-item__controls
			opacity 1
			visibility visible

		.proposal-image-item__period
		.proposal-image-item__footer
			filter blur(2px)
			+tablet()
				filter none
*/
}
@media (max-width: 500px) {
  .proposal-image-item {
    margin: 0 -15px;
  }
}
.proposal-image-item.with-shadow,
.proposal-image-item:hover {
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.13);
  box-shadow: 0 0 15px rgba(0,0,0,0.13);
}
.proposal-image-item.show-mark .proposal-image-item__change-type-holder {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.proposal-image-item.show-mark .proposal-image-item__link {
  display: none;
}
.proposal-image-item .product-list__item-promotion-label {
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px;
  z-index: 9;
}
.proposal-image-item .product-list__item-promotion-label img {
  width: 47px;
  height: 53px;
}
.proposal-image-item__body {
  position: relative;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 80px;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.proposal-image-item__header {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
}
.proposal-image-item__item-image {
  height: 110px;
  margin-top: 30px;
}
.proposal-image-item__item-image img {
  margin: auto;
  max-width: 100%;
  max-height: 110px;
}
.proposal-image-item__period {
  font-size: 10px;
  line-height: 1.2;
  color: #585858;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
}
.proposal-image-item__footer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  height: 165px;
  font-size: 35px;
}
.proposal-image-item__footer .proposal-image-item__footer-first-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-bottom: 12px;
}
.proposal-image-item__footer .proposal-image-item__footer-second-row {
  text-align: left;
  font-size: 12px;
  line-height: 1;
  color: #a2a2a2;
  padding: 15px 0 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border-top: 1px solid #efefef;
}
.proposal-image-item__footer .proposal-image-item__footer-second-row a {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.proposal-image-item__footer .proposal-image-item__footer-second-row .icon.icon-chevron-right {
  margin-left: 4.5px;
}
.proposal-image-item__reward-holder {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  -ms-flex-item-align: start;
  margin-top: -4px;
  margin-bottom: 2px;
  padding-right: 15px;
  font-family: $pfdintextcomp;
  font-size: 35px;
  display: inline-block;
  white-space: nowrap;
}
.proposal-image-item__reward-holder .proposal-image-item__reward {
  font-family: Exo;
  font-size: 32px;
  line-height: 1.25;
  display: block;
  margin-right: 5px;
  vertical-align: top;
  font-weight: 600;
  color: #79b928;
}
.proposal-image-item__reward-holder .proposal-image-item__reward.proposal-image-item__percent {
  line-height: 2.1875;
}
.proposal-image-item__reward-holder .proposal-image-item__reward-sign {
  color: #79b928;
}
.proposal-image-item__reward-holder .proposal-image-item__unit {
  color: #79b928;
  text-align: right;
  font-size: 14px;
  line-height: 1.714285714285714;
}
.proposal-image-item__reward-holder .proposal-image-item__unit img {
  width: 20px;
  margin-left: -2px;
}
.proposal-image-item__description-holder {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  text-align: left;
  overflow: hidden;
}
.proposal-image-item__description-holder hr {
  margin: 8px 0 5px;
}
@media (max-width: 380px) {
  .proposal-image-item__description-holder {
    overflow-wrap: break-word;
    max-width: 50%;
  }
}
.proposal-image-item__description-holder .proposal-image-item__coupon-description {
  margin: 0;
  font-family: ExoBold;
}
.proposal-image-item__description-holder .proposal-image-item__coupon-description.heading2 {
  font-size: 72px;
  line-height: 0.833333333333333;
  margin-bottom: 12px;
}
@media (max-width: 1300px) {
  .proposal-image-item__description-holder .proposal-image-item__coupon-description.heading2 {
    max-width: 350px;
  }
}
@media (max-width: 768px) {
  .proposal-image-item__description-holder .proposal-image-item__coupon-description.heading2 {
    max-width: 45%;
  }
}
@media (max-width: 500px) {
  .proposal-image-item__description-holder .proposal-image-item__coupon-description.heading2 {
    text-transform: none;
    font-family: $pfdintextcomp;
    font-weight: normal;
    font-size: 22px;
  }
}
.proposal-image-item__description-holder .proposal-image-item__coupon-description.heading3 {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  max-height: 60px;
  overflow: hidden;
  line-height: 1.25;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.proposal-image-item__description-holder .proposal-image-item__limit-description {
  color: #a2a2a2;
  font-size: 12px;
  line-height: 1.333333333333333;
  max-height: 45px;
  -o-text-overflow: hidden;
  text-overflow: hidden;
  overflow: hidden;
}
.proposal-image-item__limit {
  color: #a2a2a2;
  font-size: 14px;
  line-height: 1.285714285714286;
  margin: 0;
}
.proposal-image-item__change-type-holder,
.proposal-image-item__controls {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.85);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-transition: opacity 0.35s, visibility 0.35s;
  -moz-transition: opacity 0.35s, visibility 0.35s;
  -o-transition: opacity 0.35s, visibility 0.35s;
  -ms-transition: opacity 0.35s, visibility 0.35s;
  transition: opacity 0.35s, visibility 0.35s;
}
@media (max-width: 500px) {
  .proposal-image-item__controls {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    visibility: visible;
    top: auto;
    bottom: 0;
    height: calc(100% - 50px);
    background: none;
    position: relative;
    margin-top: 40px;
    padding-top: 25px;
    -webkit-box-shadow: 0px -2px 0px 0px rgba(0,0,0,0.1);
    box-shadow: 0px -2px 0px 0px rgba(0,0,0,0.1);
  }
}
.proposal-image-item__change-type-holder {
  background: rgba(255,255,255,0.6);
}
.proposal-image-item__link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (max-width: 768px) {
  .proposal-image-item__link {
    position: static;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .proposal-image-item__link .proposal-image-item__link-item {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -o-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    margin-top: 20px;
  }
}
@media (max-width: 500px) {
  .proposal-image-item__link .proposal-image-item__link-item {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -o-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .proposal-image-item__link .proposal-image-item__switcher-container {
    min-height: 70px;
  }
}
@media (max-width: 500px) {
  .proposal-image-item__link .proposal-image-item__switcher-container {
    min-height: unset;
  }
}
.proposal-image-item__link .proposal-image-item__switcher-wrapper {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.proposal-image-item__link .proposal-image-item__switcher-wrapper .proposal-image-item__restore {
  cursor: pointer;
}
.proposal-image-item__link .proposal-image-item__switcher-wrapper .proposal-image-item__restore .icon {
  margin: -2px 0 0 8px;
}
@media (max-width: 768px) {
  .proposal-image-item__link .proposal-image-item__switcher-wrapper {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .proposal-image-item__link .proposal-image-item__switcher-wrapper .proposal-image-item__restore {
    display: none;
  }
}
@media (max-width: 500px) {
  .proposal-image-item__link .proposal-image-item__switcher-wrapper .proposal-image-item__restore {
    display: none;
  }
}
.proposal-image-item__link .proposal-image-item__switcher-wrapper .proposal-image-item__switcher-title {
  font-size: 14px;
  font-family: $proxima;
  margin-bottom: 10px;
}
.proposal-image-item__link .proposal-image-item__switcher-wrapper .proposal-image-item__restore {
  margin-top: 15px;
}
.proposal-image-item__link-button {
  margin: auto;
}
@media (max-width: 768px) {
  .proposal-image-item__link-button {
    display: block;
  }
}
.proposal-image-item__change-type {
  position: absolute;
  left: 0;
  bottom: 24px;
  right: 0;
  text-align: center;
}
@media (max-width: 768px) {
  .proposal-image-item__change-type {
    bottom: 3px;
  }
}
.proposal-image-item__change-type-btn {
  margin: auto;
  font-weight: normal;
}
@media (max-width: 500px) {
  .proposal-image-item__change-type-btn {
    top: 22px;
  }
}
.proposal-image-item__change-type-btn .icon {
  margin: -2px 0 0 8px;
}
.proposal-image-item__change-type-radio {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  margin: 23px;
  padding: 0;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  border: 1px solid #79b928;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media (max-width: 768px) {
  .proposal-image-item__change-type-radio {
    top: -15px;
    left: 10px;
  }
}
.proposal-image-item__change-type-radio.marked:before {
  background: #79b928;
}
.proposal-image-item__change-type-radio:before {
  content: '';
  width: 12px;
  height: 12px;
  margin: auto;
  display: block;
  border-radius: 100%;
  background: transparent;
  -webkit-transition: background 0.35s;
  -moz-transition: background 0.35s;
  -o-transition: background 0.35s;
  -ms-transition: background 0.35s;
  transition: background 0.35s;
}
.proposal-image-item__change-type-radio:disabled {
  border-color: #a2a2a2;
}
@media (max-width: 768px) {
  .proposal-image-item.product-list__item {
    display: block;
  }
}
.proposal-image-item.product-list__item .proposal-image-item__body {
  min-height: 100%;
  width: 100%;
}
@media (max-width: 500px) {
  .proposal-image-item.product-list__item .proposal-image-item__body {
    margin-bottom: 10px;
  }
}
.proposal-image-item__not-supported {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (max-width: 768px) {
  .proposal-image-item__not-supported {
    position: static;
  }
}
.proposal-image-item__not-supported .snippet {
  max-width: 255px;
  font-family: $pfdintextcomp;
  text-align: center;
  margin: 26px 0 0 0;
  color: #333;
  font-size: 22px;
  line-height: 1.09;
}
.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #fff;
  border: 1px solid #e8e8e8;
}
.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -webkit-transition: opacity 0.25s;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.react-toggle-track {
  width: 70px;
  height: 32px;
  padding: 0;
  border-radius: 30px;
  background-color: #e8e8e8;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.react-toggle--checked .react-toggle-track {
  background-color: #79b928;
}
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #79b928;
}
.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  -ms-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.react-toggle--checked .react-toggle-thumb {
  left: 38px;
  border-color: #79b928;
}
.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  -ms-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  -ms-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle-thumb {
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -o-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -ms-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 30px;
  height: 30px;
  border: 1px solid #a2a2a2;
  border-radius: 50%;
  background-color: #fafafa;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.proposal-list .proposal-image-item {
  padding: 20px;
}
.proposal-image-item__not-supported {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (max-width: 768px) {
  .proposal-image-item__not-supported {
    position: static;
  }
}
.proposal-image-item__not-supported .snippet {
  max-width: 255px;
  font-family: $pfdintextcomp;
  text-align: center;
  margin: 26px 0 0 0;
  color: #333;
  font-size: 22px;
  line-height: 1.09;
}
.proposal-image-item.inactive-proposal {
  color: #808080;
}
.proposal-image-item.inactive-proposal .product-list__item-promotion-label,
.proposal-image-item.inactive-proposal .proposal-image-item__item-image {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.proposal-image-item.inactive-proposal .proposal-image-item__reward-sign {
  color: #fb8f97;
}
.proposal-image-item__toggle.react-toggle {
  margin-right: 25px;
}
@media (max-width: 768px) {
  .proposal-image-item__toggle.react-toggle {
    margin-right: 15px;
  }
}
.proposal-image-item__toggle.react-toggle.react-toggle--checked .react-toggle-thumb {
  left: 21px;
}
.proposal-image-item__toggle.react-toggle .react-toggle-track {
  width: 44px;
  height: 24px;
}
.proposal-image-item__toggle.react-toggle .react-toggle-thumb {
  width: 24px;
  height: 24px;
  top: 0;
}
/*# sourceMappingURL=src/js/components/ProposalsList/ProposalItem/ProposalItem.css.map */