.menu-header-wrapper {
  display: block;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 3px 7px rgba(138,138,138,0.2);
  box-shadow: 0 3px 7px rgba(138,138,138,0.2);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  width: calc(100% - 325px);
}
.menu-header-wrapper .menu-header .menu-header-search {
  display: none;
  background-color: #fff;
  width: 100%;
  margin-right: 7px;
  border-top: 1px solid #ccc;
  padding: 0 5px;
}
@media only screen and (max-width: 1100px) {
  .menu-header-wrapper .menu-header .menu-header-search {
    margin-right: 0;
  }
  .menu-header-wrapper .menu-header .menu-header-search .btn {
    padding-right: 0;
  }
}
@media only screen and (max-width: 1100px) {
  .menu-header-wrapper .menu-header .menu-header-search {
    display: none;
  }
  .menu-header-wrapper .menu-header .menu-header-search.search-bar-shown {
    display: block;
  }
  .menu-header-wrapper .menu-header .menu-header-search .side-menu-search {
    padding: 12px 8px;
  }
  .menu-header-wrapper .menu-header .menu-header-search .side-menu-search .side-menu-search__input-icon {
    bottom: 12px;
    right: 10px;
  }
  .menu-header-wrapper .menu-header .menu-header-search .side-menu-search .side-menu-search__input-icon .icon {
    width: 16px;
    height: 16px;
  }
  .menu-header-wrapper .menu-header .menu-header-search .side-menu-search input {
    border-bottom: none;
  }
}
@media (max-width: 500px) {
  .menu-header-wrapper .menu-header .menu-header-search {
    display: none;
  }
  .menu-header-wrapper .menu-header .menu-header-search.search-bar-shown-scrolled {
    display: block;
  }
}
.menu-header-wrapper .menu-header .min-width {
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media only screen and (max-width: 1100px) {
  .menu-header-wrapper {
    margin: 0;
    width: 100%;
    left: 0;
  }
  .menu-header-wrapper .menu-header .min-width {
    min-height: 60px;
    padding: 5px;
  }
}
.menu-header {
  pointer-events: auto;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  z-index: 50;
}
.menu-header .menu-logo {
  margin: 0;
  cursor: pointer;
  width: 50px;
  height: 50px;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.menu-header .menu-logo img {
  max-height: 100%;
  max-width: unset;
}
@media only screen and (max-width: 1100px) {
  .menu-header .menu-logo {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding-left: 10px;
    position: static;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@media (max-width: 768px) {
  .menu-header .menu-logo {
    width: 50px;
    height: 50px;
  }
}
.menu-header .min-width {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px;
  height: 100%;
}
@media only screen and (max-width: 1100px) {
  .menu-header .min-width {
    padding: 15px 10px;
  }
}
@media (max-width: 500px) {
  .menu-header .min-width {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -o-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.menu-header-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-left: auto;
  margin-right: 30px;
}
@media (max-width: 768px) {
  .menu-header-content {
    margin-right: 15px;
  }
}
@media (max-width: 500px) {
  .menu-header-content {
    margin-right: 0;
  }
}
.menu-header-content .menu-header-item {
  text-align: center;
  position: relative;
  cursor: pointer;
  height: 75px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media only screen and (max-width: 1100px) {
  .menu-header-content .menu-header-item {
    padding: 5px 7px 0;
    height: unset;
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -o-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    min-width: 40px;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .menu-header-content .menu-header-item {
    padding: 0 5px;
    min-width: 35px;
  }
}
.menu-header-content .menu-header-item.account {
  padding: 0 15px 0 10px;
  cursor: auto;
}
@media (max-width: 768px) {
  .menu-header-content .menu-header-item.account {
    padding: 0;
  }
}
@media (max-width: 500px) {
  .menu-header-content .menu-header-item.account {
    padding: 0 10px;
  }
}
@media (max-width: 500px) {
  .menu-header-content .menu-header-item.account {
    position: static;
  }
}
.menu-header-content .menu-header-item.account .menu-header_button {
  margin-right: 10px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .menu-header-content .menu-header-item.account .menu-header_button {
    margin-right: 0;
  }
}
.menu-header-content .menu-header-item.account .menu-header_button:hover .menu-header-item_title,
.menu-header-content .menu-header-item.account .menu-header_button:focus .menu-header-item_title {
  color: #79b928;
}
.menu-header-content .menu-header-item.account .menu-header_button:hover .icon,
.menu-header-content .menu-header-item.account .menu-header_button:focus .icon {
  color: #79b928;
}
.menu-header-content .menu-header-item:hover:not(.account) .menu-header-item_title,
.menu-header-content .menu-header-item:focus:not(.account) .menu-header-item_title {
  color: #79b928;
}
.menu-header-content .menu-header-item:hover:not(.account) .icon:not(.icon-pencil),
.menu-header-content .menu-header-item:focus:not(.account) .icon:not(.icon-pencil) {
  color: #79b928;
}
@media (max-width: 500px) {
  .menu-header-content .menu-header-item.person {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -o-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
}
.menu-header-content .menu-header-item:last-child:after {
  display: none;
}
.menu-header-content .menu-header-item:after {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  height: 55%;
  width: 1px;
  background-color: #f2f2f2;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 500px) {
  .menu-header-content .menu-header-item:after {
    display: none;
  }
}
.menu-header-content .menu-header-item .icon.icon-person {
  color: #999;
  width: 28px;
  text-align: center;
  height: 28px;
  margin: 0;
}
@media (max-width: 768px) {
  .menu-header-content .menu-header-item .icon.icon-person {
    display: none;
  }
}
@media only screen and (max-width: 1100px) {
  .menu-header-content .menu-header-item .icon.icon-person {
    width: 26px;
    height: 26px;
  }
  .menu-header-content .menu-header-item .icon.icon-person.icon-header-search {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: auto;
  }
  .menu-header-content .menu-header-item .icon.icon-person.icon-header-search svg {
    height: auto;
    width: auto;
  }
}
.menu-header-content .menu-header-item .icon.icon-person svg {
  margin: 0 auto;
}
.menu-header-content .menu-header-item .menu-header_user-vouchers {
  color: #000;
  text-align: left;
  display: block;
  font-size: 12px;
  padding: 12px 8px 0;
}
@media only screen and (max-width: 1100px) {
  .menu-header-content .menu-header-item .menu-header_user-vouchers {
    display: none;
  }
}
.menu-header-content .menu-header-item .menu-header_user-vouchers .account-available__vouchers__amount {
  margin-left: 0;
  font-size: 28px;
  font-family: $pfdintextcomp;
  padding-right: 5px;
}
.menu-header-content .menu-header-item .menu-header_user-vouchers .account-available__vouchers__amount img {
  width: 30px;
}
.menu-header-content .menu-header-item .menu-header_user-vouchers .account-available__vouchers__currency {
  margin: 0;
}
.menu-header-content .menu-header-item .menu-header-item_title {
  display: block;
  padding-top: 5px;
  line-height: 1.071428571428571;
  word-break: normal;
  white-space: pre-wrap;
  text-transform: none;
  color: #333;
  font-size: 14px;
  min-width: 35px;
  max-width: 122px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 35px;
}
@media (max-width: 768px) {
  .menu-header-content .menu-header-item .menu-header-item_title {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .menu-header-content .menu-header-item .menu-header-item_title {
    display: none;
  }
}
.menu-header-content .menu-header-item .menu-header_button {
  font-weight: normal;
  margin: 0 auto;
  padding: 10px 0 0 0;
  border: none;
  line-height: 1;
  display: block;
}
@media only screen and (max-width: 1100px) {
  .menu-header-content .menu-header-item .menu-header_button {
    padding: 0;
  }
}
.menu-header-content .menu-header-item .menu-header_button .menu-header_button-number {
  background-color: #f93;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  right: 0;
  border-radius: 10px;
}
.menu-header-content .menu-header-item .menu-header_button .menu-header_button-number .menu-header_button-value {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 500px) {
  .menu-header-content .menu-header-item .menu-header_button .menu-header_button-number {
    right: 0;
  }
}
.menu-btn {
  padding: 0;
  border: 0;
  color: #79b928;
  border-radius: 0;
  width: 40px;
  height: 40px;
  margin-left: -1px;
  display: none;
}
@media only screen and (max-width: 1100px) {
  .menu-btn {
    display: block;
  }
}
.context-list {
  -webkit-box-shadow: 0 15px 20px rgba(0,0,0,0.13);
  box-shadow: 0 15px 20px rgba(0,0,0,0.13);
  position: absolute;
  padding: 15px 10px;
  font-size: 13px;
  right: 0;
  width: 210px;
  z-index: 99;
  top: 100%;
  background-color: #fff;
}
.context-list .context-list_item {
  cursor: pointer;
  color: #666;
  display: block;
  font-size: 14px;
  text-align: left;
  text-transform: none;
  margin-bottom: 10px;
}
.context-list .context-list_item:hover {
  color: #79b928;
}
.context-list .context-list_item .context_list_item-icon {
  margin-right: 10px;
}
.context-list .context-list_item .context_list_item-icon .icon {
  width: 20px;
  height: 20px;
}
.context-list .context-list_item:last-child {
  margin-bottom: 0;
}
.context-list .context-list_item .cart-buttons-block__icon-block {
  padding-right: 5px;
}
/*# sourceMappingURL=src/js/components/Layout/Menu/MenuHeader/MenuHeader.css.map */