.bonus-preview {
  margin-top: 20px;
}
.bonus-preview .bonus-preview__header {
  margin: 10px 50px;
  font-size: 32px;
  line-height: 1;
}
@media (max-width: 500px) {
  .bonus-preview .bonus-preview__header {
    margin: 15px 25px;
    font-size: 24px;
    font-weight: 600;
  }
}
.bonus-preview .bonus-preview__content {
  padding: 35px;
  background-color: #fff;
  border-radius: 5px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media (max-width: 500px) {
  .bonus-preview .bonus-preview__content {
    padding: 25px;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.bonus-preview .bonus-preview__content .bonus-preview__data .data__title {
  margin: 0;
  font-size: 36px;
  font-weight: normal;
  line-height: 1;
  text-transform: initial;
}
@media (max-width: 500px) {
  .bonus-preview .bonus-preview__content .bonus-preview__data .data__title {
    font-size: 22px;
  }
}
.bonus-preview .bonus-preview__content .bonus-preview__data .bonus-amount {
  font-size: 64px;
  line-height: 1.2;
  color: #79b928;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 500px) {
  .bonus-preview .bonus-preview__content .bonus-preview__data .bonus-amount {
    font-size: 55px;
  }
}
.bonus-preview .bonus-preview__content .bonus-preview__data .bonus-currency {
  margin-left: 5px;
  width: 60px;
}
@media (max-width: 500px) {
  .bonus-preview .bonus-preview__content .bonus-preview__data .bonus-currency {
    font-size: 20px;
  }
}
.bonus-preview .bonus-preview__content .bonus-preview__text {
  max-width: 500px;
  margin-left: 85px;
}
@media (max-width: 500px) {
  .bonus-preview .bonus-preview__content .bonus-preview__text {
    margin-left: 0;
  }
}
.bonus-preview .bonus-preview__content .bonus-preview__text .text__title {
  margin: 10px 0 5px;
  font-size: 24px;
  line-height: 1;
  color: #79b928;
}
@media (max-width: 500px) {
  .bonus-preview .bonus-preview__content .bonus-preview__text .text__title {
    font-size: 20px;
  }
}
.bonus-preview .bonus-preview__content .bonus-preview__text .text__description {
  margin: 0;
  font-size: 18px;
  font-weight: lighter;
  line-height: 1.5;
}
@media (max-width: 768px) {
  .bonus-preview .bonus-preview__content .bonus-preview__text .text__description {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .bonus-preview .bonus-preview__content .bonus-preview__text .text__description {
    font-size: 12px;
  }
}
/*# sourceMappingURL=src/js/components/BonusPreview/BonusPreview.css.map */