@import "~vars"


.bonus-preview
	margin-top 20px

	.bonus-preview__header
		margin 10px 50px
		font-size 32px
		line-height 1
		+phone()
			margin 15px 25px
			font-size 24px
			font-weight 600

	.bonus-preview__content
		padding 35px
		background-color white
		border-radius 5px
		display flex
		align-items flex-start
		+phone()
			padding 25px
			flex-direction column

		.bonus-preview__data
			.data__title
				margin 0
				font-size 36px
				font-weight normal
				line-height 1
				text-transform initial
				+phone()
					font-size 22px

			.bonus-amount
				font-size 64px
				line-height 1.2
				color $primary
				display flex
				align-items center
				+phone()
					font-size 55px
			.bonus-currency
				margin-left 5px
				width 60px
				+phone()
					font-size 20px

		.bonus-preview__text
			max-width 500px
			margin-left 85px
			+phone()
				margin-left 0
			.text__title
				margin 10px 0 5px
				font-size 24px
				line-height 1
				color $primary
				+phone()
					font-size 20px
			.text__description
				margin 0
				font-size 18px
				font-weight lighter
				line-height 1.5
				+tablet()
					font-size 14px
				+phone()
					font-size 12px