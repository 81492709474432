.balance-preview {
  margin-top: 20px;
  padding: 25px 60px;
  background-color: #fff;
  border-radius: 5px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media only screen and (min-width: 1921px) {
  .balance-preview {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -o-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .balance-preview {
    background-color: transparent;
    padding: 0;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.balance-preview_top-wrap {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  max-width: 850px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media only screen and (max-width: 1700px) {
  .balance-preview_top-wrap {
    max-width: 950px;
  }
}
@media (max-width: 768px) {
  .balance-preview_top-wrap {
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -o-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.balance-preview_content {
  max-width: 400px;
  line-height: 1;
}
@media (max-width: 768px) {
  .balance-preview_content {
    max-width: initial;
  }
}
.balance-preview_content .content-title {
  margin: 20px 0 0;
  font-size: 32px;
}
@media (max-width: 500px) {
  .balance-preview_content .content-title {
    font-size: 24px;
    font-weight: 600;
  }
}
.balance-preview_content .content-value {
  margin-top: 15px;
  font-size: 64px;
  color: #e72f2a;
}
@media (max-width: 500px) {
  .balance-preview_content .content-value {
    font-size: 50px;
  }
}
.balance-preview_content .content-currency {
  margin-left: 5px;
  font-size: 24px;
  color: #000;
}
.balance-preview_content .content-currency img {
  width: 50px;
}
@media (max-width: 500px) {
  .balance-preview_content .content-currency {
    font-size: 20px;
  }
}
.balance-preview_content .content-description {
  margin-top: 25px;
  font-size: 18px;
  line-height: 1.45;
  color: #7b7b7b;
}
.balance-preview_content .content-description a {
  color: #79b928;
}
@media (max-width: 768px) {
  .balance-preview_content .content-description {
    margin-top: 20px;
  }
}
@media (max-width: 500px) {
  .balance-preview_content .content-description {
    margin-top: 15px;
    font-size: 14px;
  }
}
.balance-preview_content .content-diagram-title {
  margin: 20px 0 -15px;
  font-size: 24px;
  font-weight: 600;
  text-transform: initial;
  display: none;
}
@media (max-width: 500px) {
  .balance-preview_content .content-diagram-title {
    display: block;
  }
}
.balance-preview_diagram {
  margin-top: -15px;
  min-width: 350px;
  max-width: 350px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}
@media (max-width: 768px) {
  .balance-preview_diagram {
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .balance-preview_diagram {
    min-width: initial;
  }
}
.balance-preview_diagram .diagram-arc {
  -webkit-transition: stroke-width 0.35s;
  -moz-transition: stroke-width 0.35s;
  -o-transition: stroke-width 0.35s;
  -ms-transition: stroke-width 0.35s;
  transition: stroke-width 0.35s;
}
.balance-preview_diagram .diagram-arc.active {
  stroke-width: 3;
}
.balance-preview_diagram .diagram-value_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 54%;
  background-color: transparent;
  text-align: center;
  line-height: 1;
}
.balance-preview_diagram .diagram-value_wrap .diagram-value {
  font-size: 64px;
  color: #e72f2a;
}
.balance-preview_diagram .diagram-value_wrap .diagram-value img {
  width: 50px;
}
@media (max-width: 500px) {
  .balance-preview_diagram .diagram-value_wrap .diagram-value {
    font-size: 10.5vw;
  }
}
.balance-preview_diagram .diagram-value_wrap .diagram-currency {
  font-size: 24px;
}
@media (max-width: 500px) {
  .balance-preview_diagram .diagram-value_wrap .diagram-currency {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 1921px) {
  .balance-preview_balance-lines {
    margin-left: 110px;
  }
}
@media (max-width: 768px) {
  .balance-preview_balance-lines {
    margin: 5px 0 0;
    padding: 20px 30px;
    background-color: #f9f9f9;
    border-radius: 5px;
  }
}
.balance-preview_balance-lines .balance-line {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
  cursor: pointer;
}
.balance-preview_balance-lines .balance-line:last-child {
  margin-bottom: 0;
}
.balance-preview_balance-lines .balance-line.active .icon-balance {
  width: 18px;
  height: 18px;
}
.balance-preview_balance-lines .balance-line .balance-line_icon {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.balance-preview_balance-lines .balance-line .balance-line_icon .icon-balance {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
}
.balance-preview_balance-lines .balance-line .balance-line_text {
  margin-left: 15px;
}
.balance-preview_balance-lines .balance-line .balance-line_title {
  font-size: 24px;
}
@media (max-width: 500px) {
  .balance-preview_balance-lines .balance-line .balance-line_title {
    font-size: 18px;
  }
}
.balance-preview_balance-lines .balance-line .balance-line_value {
  font-size: 18px;
  color: #747474;
}
@media (max-width: 500px) {
  .balance-preview_balance-lines .balance-line .balance-line_value {
    font-size: 14px;
  }
}
/*# sourceMappingURL=src/js/components/BalancePreview/BalancePreview.css.map */